import * as THREE from 'three';

export default class Renderer {
  constructor(simulation) {
    this._simulation = simulation;
    this._addRenderer();
    this._renderer.outputEncoding = THREE.sRGBEncoding;
    this._renderer.physicallyCorrectLights = true;
  }

  _addRenderer() {
    this._renderer = new THREE.WebGLRenderer({
      canvas: this._simulation._canvas,
      alpha: true,
      antialias: true,
    });
    this._renderer.shadowMap.enabled = false;
    this._renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this._renderer.setSize(
      this._simulation._sizes.width,
      this._simulation._sizes.height
    );
    this._renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }

  onTick() {
    this._renderer.render(this._simulation._scene, this._simulation.camera);
  }
}
