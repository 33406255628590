import * as THREE from 'three';

export default class Lighting {
  _isDarkMode = document.body.classList.contains('theme-dark');
  _hemiLight;
  _dirLight;
  _dirLight2;
  _ambientLight;

  constructor(simulation) {
    this._simulation = simulation;
    this._createWorldLights();
    this._createSceneLights();
    this._setIntensity();
  }

  _createWorldLights() {
    this._hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0);
    this._hemiLight.color.setHSL(0.6, 1, 0.6);
    this._hemiLight.groundColor.setHSL(0.095, 1, 0.75);
    this._hemiLight.position.set(0, 0, 0);
    this._simulation.scene.add(this._hemiLight);

    this._dirLight = new THREE.DirectionalLight(0xffffff, 0);
    this._dirLight.color.setHSL(0.1, 1, 0.95);
    // this._dirLight.position.set(-1, 1.75, 1);
    this._dirLight.castShadow = true;
    this._dirLight.shadow.mapSize.width = 2048;
    this._dirLight.shadow.mapSize.height = 2048;
    this._dirLight.position.multiplyScalar(30);
    this._simulation.scene.add(this._dirLight);
  }

  _createSceneLights() {
    this._ambientLight = new THREE.AmbientLight(0xffffff, 0);
    this._simulation.scene.add(this._ambientLight);
  }

  _setIntensity() {
    this._hemiLight.intensity = this._isDarkMode ? 1.2 : 1.6;
    this._dirLight.intensity = this._isDarkMode ? 6 : 6;
    this._ambientLight.intensity = this._isDarkMode ? 1.6 : 3;
    // this._hemiLight.intensity = this._isDarkMode ? 1.2 : 2.6;
    // this._dirLight.intensity = this._isDarkMode ? 4 : 6;
    // this._ambientLight.intensity = this._isDarkMode ? 6 : 10;
  }

  onTick() {
    this._dirLight.position.copy(this._simulation.camera.position);
    if (this._isDarkMode !== document.body.classList.contains('theme-dark')) {
      this._isDarkMode = document.body.classList.contains('theme-dark');
      this._setIntensity();
    }
  }
}
